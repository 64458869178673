import { initCatalogFx } from 'domains/application';
import { sample } from 'effector';
import { spread } from 'patronum';
import { FEHeadersCookiesUtil } from 'utils';
import { changeLanguage } from './events';
import { $language } from './stores';
import { initUserFx } from 'domains/profile';

//@ts-expect-error
spread({
  source: sample({
    clock: changeLanguage,
    fn: (lng) => {
      FEHeadersCookiesUtil.setLanguage(lng);
      return { lng, catalog: false };
    },
  }),
  targets: {
    lng: $language,
    catalog: initCatalogFx,
    initUserFx,
  },
});
