import clsx from 'clsx';
import { Restaurant as RestaurantModel } from 'domains/cartography';
import { FEButton, FESegmentedControl } from 'components';
import style from './style.module.css';
import { Map } from './components';
import { getRestaurantStatus } from '../../../../model';
import { useTranslation } from 'react-i18next';

type Props = {
  restaurant: RestaurantModel;
  state: 'INFO' | 'MAP';
  onChangeState: (state: 'INFO' | 'MAP') => void;
  onCloseRestaurant: () => void;
  distanceTo: string | number | null;
};

const Restaurant = ({
  restaurant,
  state,
  onChangeState,
  onCloseRestaurant,
  distanceTo,
}: Props) => {
  const { t } = useTranslation();
  const { openAt, closeAt, name, status, statusInfo, address } = restaurant;

  const { title, disabled } = getRestaurantStatus(
    status,
    statusInfo,
    openAt,
    closeAt,
  );

  return (
    <div className={style.root}>
      <p className={clsx('headline', style.name)}>{name}</p>
      <div className={style.segmentedControl}>
        <FESegmentedControl<typeof state>
          items={{
            INFO: t('restaurants.restaurant.information'),
            MAP: t('restaurants.restaurant.map'),
          }}
          value={state}
          onChange={onChangeState}
        />
      </div>
      {state === 'INFO' ? (
        <>
          <div className={style.header}>
            <div className={style.headerContent}>
              <div className={style.info}>
                <p
                  className={clsx(
                    'headline',
                    !disabled ? style.worktime : style.worktime__Closed,
                  )}
                >
                  {title}
                </p>
                {distanceTo && (
                  <p className={clsx('text-1', style.distanceTo)}>
                    {distanceTo}
                  </p>
                )}
              </div>
              <p className={clsx('text-2', style.address)}>{address.address}</p>
            </div>
          </div>
          <div className={style.schedule}>
            <p className={clsx('headline', style.scheduleTitle)}>
              {t('cartography.restaurant.restaurantCard.scheduleLabel')}
            </p>
            <div className={style.scheduleItem}>
              <p>
                {t('cartography.restaurant.restaurantCard.restaurantLabel')}
              </p>
              <p>
                {t('restaurants.restaurant.dailyScheduleLabel', {
                  from: openAt,
                  to: closeAt,
                })}
              </p>
            </div>
            <div className={style.scheduleItem}>
              <p>{t('restaurants.restaurant.takingOrdersLabel')}</p>
              <p>
                {t('restaurants.restaurant.dailyScheduleLabel', {
                  from: openAt,
                  to: closeAt,
                })}
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className={style.mapContainer}>
          <Map
            className={style.map}
            restaurantPosition={{
              lat: address.location.latitude,
              lng: restaurant.address.location.longitude,
            }}
          />
        </div>
      )}
      <div className={style.buttonContainer}>
        <FEButton
          type='secondary'
          onClick={onCloseRestaurant}
          children={t(
            'cartography.restaurant.restaurantCard.buttonsLabel.backToTheList',
          )}
        />
      </div>
    </div>
  );
};

export default Restaurant;
