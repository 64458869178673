import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import clsx from 'clsx';
import { FEMapPlaceholder } from 'ui-kit/components';

export type MapProps = {
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  children: React.ReactNode;
  className: string;
  onClick: () => void;
};

const Map = ({ className, children, center, onClick }: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN ?? '',
    language: 'en',
  });

  return isLoaded ? (
    <GoogleMap
      onClick={onClick}
      mapContainerClassName={clsx(className)}
      center={center}
      zoom={10}
      options={{
        disableDefaultUI: true,
        minZoom: 10,
      }}
    >
      {children}
    </GoogleMap>
  ) : (
    <>
      <FEMapPlaceholder className={className} />
    </>
  );
};

export default Map;
