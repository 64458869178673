import { VOLGOGRAD_GEOPOSITION } from 'const';
import { Restaurant } from 'domains/cartography';
import React from 'react';
import { RestaurantsLayoutProps } from '../props';
import { Map, RestaurantsList } from './components';
import style from './style.module.css';

type DesktopRestaurantsLayoutProps = {
  immutableRestaurantsList: Restaurant[];
  center: {
    lat: number;
    lng: number;
  };
};

const DesktopRestauransLayout = ({
  immutableRestaurantsList,
  onPickRestaurant,
  onCloseRestaurant,
  center,
  ...props
}: RestaurantsLayoutProps & DesktopRestaurantsLayoutProps) => {
  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const handleCloseRestaurant = () => {
    onCloseRestaurant();
    if (map) {
      map.setCenter(VOLGOGRAD_GEOPOSITION);
      map.setZoom(10);
    }
  };

  const handlePickRestaurant = (restaurant: Restaurant) => {
    onPickRestaurant(restaurant);
    if (map) {
      const latLng = {
        lat: restaurant.address.location.latitude,
        lng: restaurant.address.location.longitude,
      };
      map.setCenter(latLng);
      map.setZoom(15);
    }
  };

  return (
    <div className={style.root}>
      <div className={style.mapContainer}>
        <Map
          currentRestaurant={props.currentRestaurant}
          center={center}
          className={style.map}
          onLoad={setMap}
          onRestaurantClick={(e, restaurant) => {
            if (e.latLng && map) {
              map.setCenter(e.latLng);
              map.setZoom(15);
            }
            onPickRestaurant(restaurant);
          }}
          restaurants={immutableRestaurantsList}
        />
        <div className={style.restaurantsListContainer}>
          <RestaurantsList
            {...{
              onPickRestaurant: handlePickRestaurant,
              onCloseRestaurant: handleCloseRestaurant,
              ...props,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopRestauransLayout;
