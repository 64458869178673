import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import clsx from 'clsx';
import markerIcon from './marker.svg';
import { FEMapPlaceholder } from 'ui-kit/components';

export type MapProps = {
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  officePosition: {
    lat: number;
    lng: number;
  };
  className: string;
};

const Map = ({ className, center, officePosition }: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN ?? '',
    language: 'en',
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={clsx(className)}
      center={center}
      zoom={12}
      options={{
        disableDoubleClickZoom: true,
        gestureHandling: 'none',
        disableDefaultUI: true,
      }}
    >
      <Marker icon={markerIcon} position={officePosition} />
    </GoogleMap>
  ) : (
    <>
      <FEMapPlaceholder className={className} />
    </>
  );
};

export default Map;
