import Cookies from 'js-cookie';
import { INTL_URL } from 'const';
import { Language } from 'models';
import { CountryCodeModel } from 'domains/cartography';

export class FEHeadersCookiesUtil {
  static setCountryCode(countryCode: CountryCodeModel | null) {
    if (countryCode !== null) {
      Cookies.set('countryCode', JSON.stringify(countryCode), {
        path: '/',
        domain:
          process.env.NODE_ENV === 'production' ? `.${INTL_URL}` : undefined,
      });
    }
  }
  static clearCountryCode() {
    Cookies.remove('countryCode');
  }
  static get getCountryCode(): CountryCodeModel | null {
    const countryCodeString = Cookies.get('countryCode');
    if (!countryCodeString) return null;

    return JSON.parse(countryCodeString) as CountryCodeModel;
  }

  static setPhoneMask(mask: string) {
    Cookies.set('phoneMask', mask, {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? `.${INTL_URL}` : undefined,
    });
  }
  static clearPhoneMask() {
    Cookies.remove('phoneMask');
  }
  static get getPhoneMask(): string | null {
    return Cookies.get('phoneMask') || null;
  }

  static setCode(code: string) {
    Cookies.set('code', code, {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? `.${INTL_URL}` : undefined,
    });
  }
  static get getCode() {
    return Cookies.get('code');
  }
  static setSign(sign: string) {
    Cookies.set('sign', sign, {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? `.${INTL_URL}` : undefined,
    });
  }
  static get getSign() {
    return Cookies.get('sign');
  }

  static clearCode() {
    Cookies.remove('code', {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? `.${INTL_URL}` : undefined,
    });
  }
  static clearSign() {
    Cookies.remove('sign', {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? `.${INTL_URL}` : undefined,
    });
  }

  static setPhone(phone: string) {
    Cookies.set('phone', phone, {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? `.${INTL_URL}` : undefined,
    });
  }
  static get getPhone() {
    return Cookies.get('phone');
  }

  static setLanguage(lng: Language) {
    Cookies.set('language', lng, {
      path: '/',
      domain:
        process.env.NODE_ENV === 'production' ? `.${INTL_URL}` : undefined,
    });
  }
  static get getLanguage() {
    return Cookies.get('language') as Language | undefined;
  }
}
